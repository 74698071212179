// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-online-class-js": () => import("./../src/templates/OnlineClass.js" /* webpackChunkName: "component---src-templates-online-class-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basepage-js": () => import("./../src/pages/basepage.js" /* webpackChunkName: "component---src-pages-basepage-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-classes-js": () => import("./../src/pages/classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-groupclasses-js": () => import("./../src/pages/groupclasses.js" /* webpackChunkName: "component---src-pages-groupclasses-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-membership-js": () => import("./../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-thankyou-js": () => import("./../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-timetable-js": () => import("./../src/pages/timetable.js" /* webpackChunkName: "component---src-pages-timetable-js" */),
  "component---src-pages-yogaposes-js": () => import("./../src/pages/yogaposes.js" /* webpackChunkName: "component---src-pages-yogaposes-js" */)
}

